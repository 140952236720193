@import '~app/mixins';

.export {
  width: 500px;
  color: #666;
  font-size: 14px;

  div {
    margin-bottom: 9px;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .caption {
    color: $color-grey;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .insufficient {
    border-radius: 5px;
    border: 1px solid $color-red;
    color: $color-red;
    //background-color: #fbcbc0;
    margin-top: 10px;
    padding: 6px;
    font-size: 13px;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
}
